<template>
    <div class="newsletter">
        <HeroBasic :heroImageUrl="hero?.image?.url" :heading="title" eyebrow="Newsletters" :hasHeaderOffset="true" />
        <div class="about">
            <div class="section-wrapper">
                <div class="top-section">
                    <Typography as="h2" variant="h2-display" transform="capitalize" class="heading">{{
                        $t('about')
                    }}</Typography>
                    <RichTextElements :elements="dek" class="dek" bodyStyle="serif-small" />
                    <AffiliationCard
                        class="affiliation-card"
                        v-if="parent?.relationTo !== 'centers'"
                        :heading="parent?.value?.title"
                        :eyebrow="parent?.value?.__typename"
                        v-bind="parent?.value"
                        :url="parent?.value?.path"
                    />
                </div>
                <SubscribeBlock
                    v-if="newsletterFormName.length && !inactiveNewsletter"
                    class="subscribe-block-parent"
                    :title="title"
                    :dek="dek"
                    :hero="hero"
                    size="33"
                    :newsletterFormName="newsletterFormName"
                />
            </div>
        </div>

        <client-only>
            <search
                class="index"
                :title="`${$t('All work from', 1, { locale: pageLanguage })} ${title}`"
                :page-language="pageLanguage"
                :index-name="`content_${pageLanguage}`"
                :filter="`collection = editions AND parent.id = ${id}`"
                :enabled-filters="{ topics: true, regions: true, years: true, experts: true }"
                :show-filters="false"
                :hideResultCount="true"
                disable-syndication-filter
            >
                <template #results="{ results }">
                    <div v-if="results" class="results-wrapper">
                        <ContentCardBlock
                            v-for="result in results"
                            :key="result.id"
                            :related="transformContentSearchIndexToContentCardBlockProps(result)"
                            size="33"
                            :hideDescription="false"
                            :isProportionate="true"
                            :showPublicationDate="true"
                            class="result"
                            new-search
                        />
                    </div>
                </template>
            </search>
        </client-only>
    </div>
</template>
<script setup>
import transformContentSearchIndexToContentCardBlockProps from '@/utils/search/transformContentSearchIndexToContentCardBlock';
import AffiliationCard from '@/components/blocks/AffiliationCard.vue';
import HeroBasic from '@/components/tiers/HeroBasic.vue';
import Typography from '@/components/typography/Typography.vue';
import SubscribeBlock from '@/components/blocks/SubscribeBlock.vue';

const { pageLanguage } = usePageDataStore();

onMounted(() => {
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'grey' });
});

const props = defineProps({
    publishedAt: {
        type: String,
        default: '',
    },
    title: {
        type: String,
        default: '',
    },
    hero: {
        type: Object,
    },
    dek: {
        type: Array,
        default: () => [],
    },
    contributors: {
        type: Array,
    },
    parent: {
        type: Object,
    },
    contentType: {
        type: String,
    },
    id: {
        type: String,
    },
    path: {
        type: String,
    },
    locale: {
        type: String,
        default: '',
    },
});

const inactiveNewsletter  = computed(() => {
    if (props.title === 'Ideas and Institutions' || props.title === 'India Tech Tracker') {
        return true}
    else return false;
});

const headerAttributesStore = useHeaderAttributesStore();

const GTMCenter = useGTM();
const GTMLang = props.locale;
const GTMType = 'Newsletter Page - ' + props.parent;
onMounted(() => {
    headerAttributesStore.updateHeaderStyleAttributes('grey');
    window.dataLayer.push({
        Center: GTMCenter.GTMCenter,
        Doctype: GTMType,
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
});

let newsletterFormName = 'CTWForm';
if (props.title === 'Carnegie Africa Program Newsletter') {
    newsletterFormName = 'CarnegieAfricaProgramForm';
} else if (props.title === 'Carnegie China Review') {
    newsletterFormName = 'CarnegieChinaForm';
} else if (props.title === 'Carnegie Europe Communiqué') {
    newsletterFormName = 'CarnegieEuropeCommuniqueForm';
} else if (props.title === 'Ideas and Institutions') {
    newsletterFormName = 'CarnegieIndiaIdeasAndInstitutionsNewsletterForm';
} else if (props.title === 'Carnegie India Newsletter') {
    newsletterFormName = 'CarnegieIndiaNewsletterForm';
} else if (props.title === 'India Tech Tracker') {
    newsletterFormName = '';
} else if (props.title === 'Carnegie Russia Eurasia Center Newsletter') {
    newsletterFormName = 'CarnegieRussiaEurasiaNewsletterForm';
}
// else if (props.title === "") {newsletterFormName = "CarnegieRussiaEurasiaRussianEditorsNoteForm"}
else if (props.title === 'China Financial Markets') {
    newsletterFormName = 'ChinaFinancialMarketsForm';
} else if (props.title === 'Diwan' || props.title === 'ديوان') {
    newsletterFormName = 'DiwanForm';
} else if (props.title === 'MENA Transitions' || props.title === 'أوجه الإنتقال في الشرق الأوسط') {
    newsletterFormName = 'MENATransitionsForm';
} else if (props.title === 'Carnegie Politika') {
    newsletterFormName = 'PolitikaForm';
} else if (props.title === 'Proliferation News') {
    newsletterFormName = 'ProliferationNewsForm';
} else if (props.title === 'Sada' || props.title === 'صدى') {
    newsletterFormName = 'SadaForm';
} else if (props.title === 'Strategic Europe') {
    newsletterFormName = 'StrategicEuropeForm';
} else if (props.title === 'Emissary') {
    newsletterFormName = 'EmissaryForm';
} else if (props.title === 'Carnegie American Statecraft Program') {
    newsletterFormName = 'CarnegieAmericanStatecraftProgramForm';
} else if (props.title === 'Next Generation Statecraft') {
    newsletterFormName = 'CarnegieAmericanStatecraftProgramForm';
} else if (props.title === 'Carnegie Asia Program') {
    newsletterFormName = 'CarnegieAsiaProgramForm';
} else if (props.title === 'Carnegie California') {
    newsletterFormName = 'CarnegieCarnegieCaliforniaProgramForm';
} else if (props.title === 'Carnegie Democracy, Conflict, and Governance Program') {
    newsletterFormName = 'CarnegieDemocracy,Conflict,andGovernanceProgramForm';
} else if (props.title === 'Carnegie Europe Program') {
    newsletterFormName = 'CarnegieEuropeProgramForm';
} else if (props.title === 'Carnegie Global Order and Institutions Program') {
    newsletterFormName = 'CarnegieGlobalOrderandInstitutionsProgramForm';
} else if (props.title === 'Carnegie Middle East Program') {
    newsletterFormName = 'CarnegieMiddleEastProgramForm';
} else if (props.title === 'Carnegie Nuclear Policy Program') {
    newsletterFormName = 'CarnegieNuclearPolicyProgramForm';
} else if (props.title === 'Carnegie Russia and Eurasia Program') {
    newsletterFormName = 'CarnegieRussiaandEurasiaProgramForm';
} else if (props.title === 'Carnegie South Asia Program') {
    newsletterFormName = 'CarnegieSouthAsiaProgramForm';
} else if (props.title === 'Carnegie Sustainability, Climate, and Geopolitics Program') {
    newsletterFormName = 'CarnegieSustainability,Climate,andGeopoliticsProgramForm';
} else if (props.title === 'Carnegie Technology and International Affairs Program') {
    newsletterFormName = 'CarnegieTechnologyandInternationalAffairsProgramForm';
} else if (props.title === 'Carnegie Connects') {
    newsletterFormName = 'CarnegieConnects';
} else if (props.title === 'Как это работает?') {
    newsletterFormName = 'CarnegieRussiaEurasiaRussianHowItWorksForm';
}
</script>
<style lang="scss" scoped>
.newsletter {
    background-color: color(grey, light);
}

.about {
    .section-wrapper {
        @include content-section;
        padding-top: vertical-space(3);

        @include media-query(not-phone) {
            @include grid($layout: '33');
        }
    }
}

.top-section {
    grid-column: 1 / span 2;
    @include content-area-padding;
}

.subscribe-block {
    grid-column: 3 / span 1;
    margin-bottom: 2em;
}

.affiliation-card {
    grid-column: 1 / span 2;
    margin-top: vertical-space(2);
    margin-bottom: vertical-space(3);

    :deep(.image-wrapper) {display: none}
}

.results {
    background-color: color(blanc);
}
</style>
